import React from 'react'
import Layout from "../components/layout"
import {Link} from 'gatsby'

const ThankYou = () => {

    return (
        <Layout>
            <div className="flex flex-col items-center content-center h-screen">
                <div className="bg-gray-100 content-center flex flex-col items-center min-w-sm mt-16 max-w-sm p-8 rounded">
                    <div className="mb-3 text-green-500">
                        <svg className="w-16 h-16 fill-current" viewBox="0 0 24 24">
                            <path d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" />
                        </svg>
                    </div>
                    <p className="text-xl font-bold text-center text-brand-800 mb-6">
                        Thank you for your order!
                    </p>
                    <p className="text-center text-brand-800 mb-6">
                        We just sent you an email with your order confirmation. Once we ship your order,
                        we will send you the tracking number so that you can keep udpated on the delivery time.
                    </p>
                    <Link to={'/'} className="bg-brand-600 hover:bg-brand-700 text-white px-4 py-1 rounded">
                        Back to the main page
                    </Link>
                </div>
            </div>
        </Layout>
    )
}

export default ThankYou